<template>
  <ul class="nav nav-tabs nav-tabs--transparent mb-3">
    <li class="nav-item" v-if="canView(PERMS.ORDERS_DDMP_MANAGEMENT)">
      <div
        class="nav-link"
        v-bind:class="{ active: isView('ddmp') }"
        @click.prevent="onClick('ddmp')"
      >
        {{ $t("role.orders.manage.ddmp.title") }}
      </div>
    </li>
    <li class="nav-item" v-if="canView(PERMS.ORDERS_DDMI_MANAGEMENT)">
      <div
        class="nav-link"
        v-bind:class="{ active: isView('ddmi') }"
        @click.prevent="onClick('ddmi')"
      >
        {{ $t("role.orders.manage.ddmi.title") }}
      </div>
    </li>
    <li class="nav-item">
      <div
        class="nav-link"
        v-bind:class="{ active: isView('incident') }"
        @click.prevent="onClick('incident')"
      >
        {{ $t("role.orders.manage.incidents.title") }}
      </div>
    </li>
  </ul>
</template>

<script>
import { PERMS } from "../../../../../helpers/acl";

export default {
  name: "OrdersManageNav",

  data() {
    return {
      PERMS,
    };
  },

  methods: {
    isView(view) {
      return this.$store.state.orders.view === view;
    },

    onClick(view) {
      this.$router.push({ query: { view } }).catch(() => {});
      this.$store.commit("orders/setView", view);
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
